/* Mobile-first base styles */
body, html {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Cinzel', serif;
  overflow-x: hidden;
  min-height: 100vh;
  font-size: 16px; /* Base font size for better mobile readability */
}

.container {
  position: relative;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.backgroundImage {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
  filter: blur(20px) brightness(60%);
}

/* Header Styles */
.header {
  position: sticky;
  top: 0;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  padding: 5px 0;
  text-align: center;
  z-index: 100;
}

.title {
  color: #ffd700;
  font-size: 2em; /* Smaller base size for mobile */
  font-weight: bold;
  margin: 0;
  padding: 10px;
}

/* Hero Section Styles */
.hero {
  flex: 1;
  padding: 15px;
  text-align: center;
  color: #fff;
  background: rgba(0, 0, 0, 0.7);
  margin: 10px;
  border-radius: 10px;
  margin-bottom: 60px;
}

.welcomeTitle {
  color: #ffd700;
  font-size: 1.8em;
  margin-bottom: 15px;
}

.heroDescription, .heroDetails {
  font-size: 1em;
  margin: 12px;
  line-height: 1.6;
}

/* Content Container Styles */
.contentContainer {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 15px; /* Reduced padding for mobile */
  background: rgba(0, 0, 0, 0.6);
  margin: 20px 10px; /* Adjusted margin for mobile */
  border-radius: 10px;
  width: auto; /* Changed from percentage to auto */
  max-width: 100%; /* Ensure it doesn't overflow */
  box-sizing: border-box; /* Ensure padding is included in width */
}

.openingTimesContainer, .barCamContainer {
  background: #333;
  color: #ffd700;
  padding: 15px; /* Reduced padding for mobile */
  border-radius: 10px;
  width: auto; /* Changed from 100% to auto */
  box-sizing: border-box;
  margin: 0; /* Remove any default margins */
}

.barCamTitle, .openingTimesTitle {
  font-size: 1.5em;
  margin-bottom: 15px;
}

.openingTimesList {
  list-style: none;
  padding: 0;
  margin: 0;
}

.openingTimesItem {
  display: flex;
  justify-content: space-between;
  font-size: 0.9em;
  margin: 8px 0;
  padding: 5px 0;
  color: #fff;
}

/* Footer Styles */
.footer {
  position: relative;
  width: 100%;
  background: rgba(0, 0, 0, 0.7);
  color: #fff;
  text-align: center;
  padding: 10px;
  margin-top: auto;
}

.socialLinks {
  display: flex;
  justify-content: center;
  gap: 15px;
  margin-bottom: 8px;
}

.socialIcon {
  display: inline-block;
}

.socialImage {
  width: 25px;
  height: 25px;
}

/* Cookie Banner Styles */
.cookieBanner {
  position: fixed;
  bottom: 0;
  width: 100%;
  background: rgba(0, 0, 0, 0.9);
  color: #fff;
  padding: 10px;
  font-size: 0.9em;
  z-index: 1000;
}

.cookieBanner p {
  margin: 0;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}

.acceptButton {
  background: #ffd700;
  color: #000;
  border: none;
  padding: 8px 16px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1em;
}

/* Overlay Styles */
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 200;
  padding: 15px;
  box-sizing: border-box;
}

.overlayContent {
  position: relative;
  width: 95%;
  height: 90vh;
  background: #000;
  padding: 15px;
  border-radius: 10px;
  text-align: center;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
}

.closeButton {
  position: absolute;
  top: 10px;
  right: 10px;
  background: #ffd700;
  color: #000;
  border: none;
  padding: 8px 16px;
  border-radius: 5px;
  cursor: pointer;
  z-index: 201;
}

.iframe, .overlayContent img {
  width: 100%;
  height: calc(100% - 40px); /* Account for close button */
  max-height: unset;
  border-radius: 10px;
  margin-top: 40px; /* Space for close button */
  object-fit: contain; /* For the barcam image */
}

/* Specific styles for the map iframe */
iframe#gmap_canvas {
  border: none;
  width: 100%;
  height: calc(100% - 40px);
  margin-top: 40px;
}

.webcamOffline {
  color: #fff;
  margin-top: 20px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.barCamContainer {
  cursor: pointer;
  transition: transform 0.3s;
}

.barCamContainer:hover {
  transform: scale(1.02); /* Reduced scale effect for mobile */
}

/* Tablet and Desktop Media Queries */
@media screen and (min-width: 768px) {
  .title {
    font-size: 4em;
  }
  
  .contentContainer {
    flex-direction: row;
    justify-content: center; /* Center the containers */
    width: calc(100% - 40px); /* Account for margin */
    max-width: 800px; /* Limit maximum width */
    padding: 20px;
    margin: 20px auto;
    gap: 40px; /* Increase gap between containers */
  }
  
  .openingTimesContainer, .barCamContainer {
    flex: 1; /* Both containers take equal space */
    width: calc(50% - 20px); /* Account for gap */
    max-width: 380px; /* Prevent containers from getting too wide */
    padding: 20px;
  }
  
  .heroDescription, .heroDetails {
    font-size: 1.2em;
  }
  
  .cookieBanner p {
    flex-direction: row;
    justify-content: space-between;
  }
  
  .overlayContent {
    width: 80%;
    max-width: 900px;
  }

  .barCamContainer:hover {
    transform: scale(1.05); /* Restore original scale effect for desktop */
  }
}

@media screen and (min-width: 1024px) {
  .hero {
    max-width: 1000px;
    margin: 20px auto 80px auto;
  }

  .contentContainer {
    max-width: 1000px; /* Increased max-width for larger screens */
  }

  .openingTimesContainer, .barCamContainer {
    max-width: 460px; /* Adjusted for larger screens */
  }
  
  .title {
    font-size: 5em;
  }
  
  .welcomeTitle {
    font-size: 2.5em;
  }
}

/* Handle smaller phones */
@media screen and (max-width: 360px) {
  .title {
    font-size: 1.8em;
  }
  
  .welcomeTitle {
    font-size: 1.5em;
  }
  
  .heroDescription, .heroDetails {
    font-size: 0.9em;
  }
}

@media screen and (orientation: landscape) and (max-height: 600px) {
  .overlayContent {
    height: 85vh;
  }
  
  .closeButton {
    top: 5px;
    right: 5px;
    padding: 5px 10px;
  }
  
  .iframe, .overlayContent img, iframe#gmap_canvas {
    margin-top: 30px;
    height: calc(100% - 30px);
  }
}
